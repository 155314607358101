import request from '@/plugins/axios'

// 订单列表其他列表参数
export const apiOtherLists = () => request.get('/order.set_meal_order/searchParam')

// 订单详情
export const apiOrderDetail = (params: any) => request.get('/order.set_meal_order/detail', { params })

// 订单列表
export const apiOrderLists = (params: any) => request.get('/order.set_meal_order/lists', { params })

// 订单备注
export const apiOrderRemarks = (params: any) => request.post('/order.set_meal_order/remark', params)

// 确认支付
export const apiOrderConfirmPay = (params: any) => request.post('/order.set_meal_order/confirmPay', params)


// 订单列表其他列表参数
export const apiGameAppPackageOtherLists = () => request.get('/order.game_app_package_set_meal_order/searchParam')

// 订单详情
export const apiGameAppPackageOrderDetail = (params: any) => request.get('/order.game_app_package_set_meal_order/detail', { params })

// 订单列表
export const apiGameAppPackageOrderLists = (params: any) => request.get('/order.game_app_package_set_meal_order/lists', { params })

// 订单备注
export const apiGameAppPackageOrderRemarks = (params: any) => request.post('/order.game_app_package_set_meal_order/remark', params)

// 确认支付
export const apiGameAppPackageOrderConfirmPay = (params: any) => request.post('/order.game_app_package_set_meal_order/confirmPay', params)
